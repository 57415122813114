var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reserveDialog" },
    [
      _c("c-dialog", {
        attrs: {
          title: "预订",
          width: 700,
          showDialog: _vm.openDialog,
          beforeClose: _vm.handleClose,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.openDialog = $event
          },
          "update:show-dialog": function ($event) {
            _vm.openDialog = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c(
                      "el-scrollbar",
                      [
                        _c(
                          "el-form",
                          {
                            ref: "form",
                            attrs: {
                              "label-width": "100px",
                              model: _vm.billOrderReq,
                              rules: _vm.rules,
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "提货模式" } },
                              [
                                _c("div", { staticClass: "tabs x-x" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tab x-fc curP",
                                      class: {
                                        active: _vm.billOrderReq.fetchType == 1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getCurDeliveryTab(1)
                                        },
                                      },
                                    },
                                    [_vm._v(" 门店自提 ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tab x-fc curP",
                                      class: {
                                        active: _vm.billOrderReq.fetchType == 2,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getCurDeliveryTab(2)
                                        },
                                      },
                                    },
                                    [_vm._v(" 商家配送 ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tab x-fc curP",
                                      class: {
                                        active: _vm.billOrderReq.fetchType == 3,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getCurDeliveryTab(3)
                                        },
                                      },
                                    },
                                    [_vm._v(" 快递配送 ")]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.billOrderReq.fetchType == 1,
                                    expression: "billOrderReq.fetchType == 1",
                                  },
                                ],
                                staticClass: "storeSelfPickup",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "客户名称",
                                          prop: "receiverName",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "name",
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入客户名称",
                                            id: "receiverName",
                                          },
                                          model: {
                                            value:
                                              _vm.billOrderReq.receiverName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "receiverName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.receiverName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "联系电话",
                                          prop: "receiverPhone",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "phone",
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入联系电话",
                                          },
                                          model: {
                                            value:
                                              _vm.billOrderReq.receiverPhone,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "receiverPhone",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.receiverPhone",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "取货门店",
                                          prop: "fetchShopId",
                                        },
                                      },
                                      [
                                        _c("SelectRemote", {
                                          staticStyle: { width: "250px" },
                                          attrs: {
                                            option: _vm.$select({
                                              key: "listShop",
                                            }).option,
                                          },
                                          on: {
                                            selectChange: _vm.changeFetchShop,
                                          },
                                          model: {
                                            value: _vm.billOrderReq.fetchShopId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "fetchShopId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.fetchShopId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "生产部门",
                                          prop: "produceDeptId",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请选择生产部门",
                                              filterable: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.billOrderReq.produceDeptId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billOrderReq,
                                                  "produceDeptId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billOrderReq.produceDeptId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.decorationRoomList,
                                            function (item) {
                                              return _c(
                                                "el-option",
                                                {
                                                  key: item.decorationRoomId,
                                                  attrs: {
                                                    label:
                                                      item.decorationRoomName,
                                                    value:
                                                      item.decorationRoomId,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "x-bc" },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.decorationRoomName
                                                            )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "取货时间",
                                          prop: "fetchDate",
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            size: "mini",
                                            "value-format":
                                              "yyyy-MM-dd HH:mm:ss",
                                            type: "date",
                                            placeholder: "选择日期",
                                            "picker-options": _vm.pickerOptions,
                                          },
                                          on: { change: _vm.fetchDateChange },
                                          model: {
                                            value: _vm.billOrderReq.fetchDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "fetchDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.fetchDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          prop: "fetchTime",
                                          "label-width": "0",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请选择取货时间段",
                                              filterable: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.billOrderReq.fetchTime,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billOrderReq,
                                                  "fetchTime",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billOrderReq.fetchTime",
                                            },
                                          },
                                          _vm._l(
                                            _vm.fetchTimeList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item,
                                                attrs: {
                                                  label: item,
                                                  value: item,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "提醒时间" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "marR10",
                                      attrs: {
                                        size: "mini",
                                        "controls-position": "right",
                                        min: 1,
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.billOrderReq.fetchRemindHour,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.billOrderReq,
                                            "fetchRemindHour",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "billOrderReq.fetchRemindHour",
                                      },
                                    }),
                                    _c("span", [_vm._v("小时")]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "手工单据号" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入手工单据号",
                                            maxlength: 40,
                                          },
                                          model: {
                                            value: _vm.billOrderReq.handBillNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "handBillNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.handBillNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "磅数" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入磅数",
                                            maxlength: 40,
                                          },
                                          model: {
                                            value:
                                              _vm.billOrderReq.handPointSize,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "handPointSize",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.handPointSize",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "尺寸" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入尺寸",
                                            maxlength: 40,
                                          },
                                          model: {
                                            value: _vm.billOrderReq.handSize,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "handSize",
                                                $$v
                                              )
                                            },
                                            expression: "billOrderReq.handSize",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "样式" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入样式",
                                            maxlength: 40,
                                          },
                                          model: {
                                            value: _vm.billOrderReq.handStyle,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "handStyle",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.handStyle",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "备注" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "remark",
                                      attrs: {
                                        size: "mini",
                                        type: "textarea",
                                        rows: 2,
                                        placeholder: "请输入备注",
                                      },
                                      model: {
                                        value: _vm.billOrderReq.remark,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.billOrderReq,
                                            "remark",
                                            $$v
                                          )
                                        },
                                        expression: "billOrderReq.remark",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "上传图片" } },
                                  [
                                    _c("AccessoryUpload", {
                                      attrs: {
                                        listType: "picture-card",
                                        limit: 10,
                                        title: "",
                                        fileList:
                                          _vm.billOrderReq.imageUrls.map(
                                            function (x) {
                                              return { url: x }
                                            }
                                          ),
                                      },
                                      on: {
                                        getFileItems: _vm.getFileItemsData,
                                        delFileItems: _vm.delFileItems,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("el-scrollbar"),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.billOrderReq.fetchType == 2,
                                    expression: "billOrderReq.fetchType == 2",
                                  },
                                ],
                                staticClass: "merchantDelivery",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "收货人",
                                          prop: "receiverName",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "name",
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入收货人",
                                          },
                                          model: {
                                            value:
                                              _vm.billOrderReq.receiverName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "receiverName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.receiverName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "收货人电话",
                                          prop: "receiverPhone",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "phone",
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入收货人电话",
                                          },
                                          model: {
                                            value:
                                              _vm.billOrderReq.receiverPhone,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "receiverPhone",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.receiverPhone",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "收货人地址",
                                          prop: "receiverRegionId",
                                        },
                                      },
                                      [
                                        _c("el-cascader", {
                                          ref: "cascader",
                                          attrs: {
                                            options: _vm.allRegionList,
                                            props: _vm.defaultProp,
                                            filterable: "",
                                            clearable: "",
                                          },
                                          on: { change: _vm.designerChange },
                                          model: {
                                            value:
                                              _vm.billOrderReq.receiverRegionId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "receiverRegionId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.receiverRegionId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "详细地址",
                                          prop: "receiverRegionAddress",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入详细地址",
                                          },
                                          model: {
                                            value:
                                              _vm.billOrderReq
                                                .receiverRegionAddress,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "receiverRegionAddress",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.receiverRegionAddress",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "配送门店",
                                          prop: "fetchShopId",
                                        },
                                      },
                                      [
                                        _c("SelectRemote", {
                                          staticStyle: { width: "250px" },
                                          attrs: {
                                            option: _vm.$select({
                                              key: "listShop",
                                            }).option,
                                          },
                                          on: {
                                            selectChange: _vm.changeFetchShop,
                                          },
                                          model: {
                                            value: _vm.billOrderReq.fetchShopId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "fetchShopId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.fetchShopId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "生产部门",
                                          prop: "produceDeptId",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请选择生产部门",
                                              filterable: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.billOrderReq.produceDeptId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billOrderReq,
                                                  "produceDeptId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billOrderReq.produceDeptId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.decorationRoomList,
                                            function (item) {
                                              return _c(
                                                "el-option",
                                                {
                                                  key: item.decorationRoomId,
                                                  attrs: {
                                                    label:
                                                      item.decorationRoomName,
                                                    value:
                                                      item.decorationRoomId,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "x-bc" },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.decorationRoomName
                                                            )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "配送时间",
                                          prop: "fetchDate",
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            size: "mini",
                                            "value-format":
                                              "yyyy-MM-dd HH:mm:ss",
                                            type: "date",
                                            placeholder: "选择日期",
                                            "picker-options": _vm.pickerOptions,
                                          },
                                          on: { change: _vm.fetchDateChange },
                                          model: {
                                            value: _vm.billOrderReq.fetchDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "fetchDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.fetchDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          prop: "fetchTime",
                                          "label-width": "0",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请选择配送时间段",
                                              filterable: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.billOrderReq.fetchTime,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billOrderReq,
                                                  "fetchTime",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billOrderReq.fetchTime",
                                            },
                                          },
                                          _vm._l(
                                            _vm.fetchTimeList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item,
                                                attrs: {
                                                  label: item,
                                                  value: item,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "配送方式" } },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: _vm.billOrderReq.deliveryMode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.billOrderReq,
                                              "deliveryMode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "billOrderReq.deliveryMode",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("自营配送")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("众包配送")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 2 } },
                                          [_vm._v("美团专送")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "提醒时间" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "marR10",
                                      attrs: {
                                        size: "mini",
                                        "controls-position": "right",
                                        min: 1,
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.billOrderReq.fetchRemindHour,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.billOrderReq,
                                            "fetchRemindHour",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "billOrderReq.fetchRemindHour",
                                      },
                                    }),
                                    _c("span", [_vm._v("小时")]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "手工单据号" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入手工单据号",
                                            maxlength: 40,
                                          },
                                          model: {
                                            value: _vm.billOrderReq.handBillNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "handBillNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.handBillNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "磅数" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入磅数",
                                            maxlength: 40,
                                          },
                                          model: {
                                            value:
                                              _vm.billOrderReq.handPointSize,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "handPointSize",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.handPointSize",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "尺寸" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入尺寸",
                                            maxlength: 40,
                                          },
                                          model: {
                                            value: _vm.billOrderReq.handSize,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "handSize",
                                                $$v
                                              )
                                            },
                                            expression: "billOrderReq.handSize",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "样式" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入样式",
                                            maxlength: 40,
                                          },
                                          model: {
                                            value: _vm.billOrderReq.handStyle,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "handStyle",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.handStyle",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "备注" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "remark",
                                      attrs: {
                                        size: "mini",
                                        type: "textarea",
                                        rows: 2,
                                        placeholder: "请输入备注",
                                      },
                                      model: {
                                        value: _vm.billOrderReq.remark,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.billOrderReq,
                                            "remark",
                                            $$v
                                          )
                                        },
                                        expression: "billOrderReq.remark",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "上传图片" } },
                                  [
                                    _c("AccessoryUpload", {
                                      attrs: {
                                        listType: "picture-card",
                                        limit: 10,
                                        title: "",
                                        fileList:
                                          _vm.billOrderReq.imageUrls.map(
                                            function (x) {
                                              return { url: x }
                                            }
                                          ),
                                      },
                                      on: {
                                        getFileItems: _vm.getFileItemsData,
                                        delFileItems: _vm.delFileItems,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.billOrderReq.fetchType == 3,
                                    expression: "billOrderReq.fetchType == 3",
                                  },
                                ],
                                staticClass: "merchantDelivery",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "收货人",
                                          prop: "receiverName",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "name",
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入收货人",
                                          },
                                          model: {
                                            value:
                                              _vm.billOrderReq.receiverName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "receiverName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.receiverName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "收货人电话",
                                          prop: "receiverPhone",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "phone",
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入收货人电话",
                                          },
                                          model: {
                                            value:
                                              _vm.billOrderReq.receiverPhone,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "receiverPhone",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.receiverPhone",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "收货人地址",
                                          prop: "receiverRegionId",
                                        },
                                      },
                                      [
                                        _c("el-cascader", {
                                          ref: "cascader",
                                          attrs: {
                                            options: _vm.allRegionList,
                                            props: _vm.defaultProp,
                                            filterable: "",
                                            clearable: "",
                                          },
                                          on: { change: _vm.designerChange },
                                          model: {
                                            value:
                                              _vm.billOrderReq.receiverRegionId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "receiverRegionId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.receiverRegionId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "详细地址",
                                          prop: "receiverRegionAddress",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入详细地址",
                                          },
                                          model: {
                                            value:
                                              _vm.billOrderReq
                                                .receiverRegionAddress,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "receiverRegionAddress",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.receiverRegionAddress",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "配送门店",
                                          prop: "fetchShopId",
                                        },
                                      },
                                      [
                                        _c("SelectRemote", {
                                          staticStyle: { width: "250px" },
                                          attrs: {
                                            option: _vm.$select({
                                              key: "listShop",
                                            }).option,
                                          },
                                          on: {
                                            selectChange: _vm.changeFetchShop,
                                          },
                                          model: {
                                            value: _vm.billOrderReq.fetchShopId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "fetchShopId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.fetchShopId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "生产部门",
                                          prop: "produceDeptId",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请选择生产部门",
                                              filterable: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.billOrderReq.produceDeptId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billOrderReq,
                                                  "produceDeptId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billOrderReq.produceDeptId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.decorationRoomList,
                                            function (item) {
                                              return _c(
                                                "el-option",
                                                {
                                                  key: item.decorationRoomId,
                                                  attrs: {
                                                    label:
                                                      item.decorationRoomName,
                                                    value:
                                                      item.decorationRoomId,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "x-bc" },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.decorationRoomName
                                                            )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "手工单据号" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入手工单据号",
                                            maxlength: 40,
                                          },
                                          model: {
                                            value: _vm.billOrderReq.handBillNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "handBillNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.handBillNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "磅数" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入磅数",
                                            maxlength: 40,
                                          },
                                          model: {
                                            value:
                                              _vm.billOrderReq.handPointSize,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "handPointSize",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.handPointSize",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "尺寸" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入尺寸",
                                            maxlength: 40,
                                          },
                                          model: {
                                            value: _vm.billOrderReq.handSize,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "handSize",
                                                $$v
                                              )
                                            },
                                            expression: "billOrderReq.handSize",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "样式" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入样式",
                                            maxlength: 40,
                                          },
                                          model: {
                                            value: _vm.billOrderReq.handStyle,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.billOrderReq,
                                                "handStyle",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "billOrderReq.handStyle",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "备注" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "remark",
                                      attrs: {
                                        size: "mini",
                                        type: "textarea",
                                        rows: 2,
                                        placeholder: "请输入备注",
                                      },
                                      model: {
                                        value: _vm.billOrderReq.remark,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.billOrderReq,
                                            "remark",
                                            $$v
                                          )
                                        },
                                        expression: "billOrderReq.remark",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "上传图片" } },
                                  [
                                    _c("AccessoryUpload", {
                                      attrs: {
                                        listType: "picture-card",
                                        limit: 10,
                                        title: "",
                                        fileList:
                                          _vm.billOrderReq.imageUrls.map(
                                            function (x) {
                                              return { url: x }
                                            }
                                          ),
                                      },
                                      on: {
                                        getFileItems: _vm.getFileItemsData,
                                        delFileItems: _vm.delFileItems,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handleClose(1)
                      },
                    },
                  },
                  [_vm._v("确定")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "cancelBtn",
                    on: {
                      click: function ($event) {
                        return _vm.handleClose(2)
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }